// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCkBJh7-D0obgIFcpCgybe-nZd3Ryds_FA",
  authDomain: "sreeleshaa.firebaseapp.com",
  projectId: "sreeleshaa",
  storageBucket: "sreeleshaa.firebasestorage.app",
  messagingSenderId: "979088911008",
  appId: "1:979088911008:web:88ebae5e4b3f8d499b37c5",
  measurementId: "G-2248213HDJ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);