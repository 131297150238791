import React from "react";

const ServicesSubSection = (data) => {
  
  const { id, title, skills } = data?.data;

  return (
    <div className="py-4">
      <div>
        <div className="text-left text-5xl font-extralight flex flex-wrap">
          <span className="text-green-400 font-semibold mr-2">{id}.</span>
          <span>{title}</span>
        </div>
        <div className="py-10">
          <ul className="flex text-3xl font-thin items-center flex-wrap">
            {skills.map((data) => (
              <li className="border-[0.01rem] border-white px-10 py-2">{data}</li>
            ))}

            
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServicesSubSection;
