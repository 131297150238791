import React from 'react'

const AboutSection = () => {
  return (
    <div>
        
    <div className="py-10 sm:px-24 px-6 text-center ">
      <div className='space-y-2'>
        {" "}
        <div className="text-xl text-left text-green-400">About me</div>
        <div className="sm:text-6xl text-4xl font-semibold  sm:font-normal text-left  ">
        A Developer and<br/> Creative Designer
        </div>
        <div className='text-left text-xl font-extralight font-sans tracking-wider'>Passionate about crafting engaging 
        digital experiences. </div>
        <div className="sm:py-10">
        

        </div>
      </div>
      <div></div>
    </div>
 
      
    </div>
  )
}

export default AboutSection
