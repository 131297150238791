import React from "react";
import { BG_LINK, MOUSE_ICON } from "../../utils/constants";
import { TITLE_DESCRIPTION } from "../../utils/mockDate";

const MainContainer = () => {
  return (
    <div className="overflow-hidden  h-screen">
        <div className="absolute z-40 w-7 sm:w-9 bottom-6 sm:bottom-16 left-[45%] sm:left-[50%]">
            <img  alt="mouse" src={MOUSE_ICON}/>
        </div>
      <div className="absolute z-50 bottom-20 sm:bottom-48 left-8 sm:left-24 space-y-3 ">
        <div className="text-5xl sm:text-8xl font-semibold text-gray-300">Sreelesh A</div>
        <div className="font-extralight sm:font-light pr-4  sm:w-[60%]">{TITLE_DESCRIPTION}</div>
        <div className="flex gap-x-3 py-3">
          <div className=" bg-gradient-to-br from-green-400 to-green-800  w-24 text-center py-2 rounded-md my-auto">Hire Me</div>
          <div className="border-green-500 text-green-500 border-[.01rem] w-40 text-center py-2 rounded-md my-auto">Explore My Skills</div>
        </div>
      </div>
      <div className=" bg-black">
        <img
          className="sm:w-2/3 w-full sm:ml-auto -ml-4 scale-150 pt-28 sm:scale-150 sm:py-32 opacity-70 "
          alt="bg-sreelesh"
          src={BG_LINK}
        />
      </div>
    </div>
  );
};

export default MainContainer;


