import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";

const ClientsCarousel = () => {
  const clientData = useSelector((store) => store.client);
//   console.log(clientData[0]?.name);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],

    cssEase: "linear",
  }; 

  return (
    <div className="sm:px-40 px-8">
      <div className="text-white slider-container">
        <Slider {...settings}>
          {clientData && clientData.map((data) => (
            <div ey={data?.name}>
                <div className="border-[0.01rem] px-2 py-4 scale-90 border-white">
                <div k className="text-white ">

                </div>
                <div className="flex items-center gap-x-4 justify-center">
                    <div className=""><img className="rounded-full size-10" src={data?.profile_link} alt="client-image "/></div>
                    {data?.name}</div>

             
                
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ClientsCarousel;
