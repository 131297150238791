import { createSlice } from "@reduxjs/toolkit";

export const profileSlice=createSlice({
    name: "portfolio",
    initialState:{
        portfolio:null,
        designPortfolio:null,
    },
    reducers:{
        getPortfolio: (state,action)=>{
            state.portfolio = action.payload
        },
        getDesignPortfolio: (state,action)=>{
            state.designPortfolio = action.payload
        }
    }
})

export const {getPortfolio,getDesignPortfolio}=profileSlice.actions
export default profileSlice.reducer