import React from 'react'
import Header from './Header'
import MainContainer from './subFolders/MainContainer'
import ClientsSection from './subFolders/ClientsSection'
import AboutSection from './subFolders/AboutSection'
import ServicesSection from './subFolders/ServicesSection'
import PortfolioSection from './subFolders/PortfolioSection'

const Home = () => {
  return (
    <div>
        <Header/>
        <MainContainer/>
        <ClientsSection/>
        <AboutSection/>
        <ServicesSection/>
        <PortfolioSection/>
        

        
      
    </div>
  )
}

export default Home
