import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/Home";
import { Provider } from "react-redux";
import store from "./appStore/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: (
        <>

          <Home />
        </>
      ),
    },
  ]);
  return (
    <div className="bg-black text-white">
      <Provider store={store}>
      <RouterProvider router={appRouter} />
      </Provider>
    </div>
  );
}

export default App;
