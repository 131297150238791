import React from "react";
import ServicesSubSection from "./ServicesSubSection";

const ServicesSection = () => {
    const visualDesign={id:'01',
        title:"Visual Design",
        skills:["Creative Poster", "Motion Graphics","UX/UI","Video Editing"]
    }

    const development={id:'02',
        title:"Development",
        skills:["React.JS", "JavaScript","Tailwind","MongoDB","Node.JS"]
    }


  return (
    <div>
      <div className="py-10 sm:px-24 px-6 text-center ">
        <div className="space-y-2">
          {" "}
          <div className="text-xl text-center text-green-400">Services</div>
          <div className=" sm:text-6xl text-4xl sm:font-normal font-semibold  text-center  ">
            Available service <br /> that i can work on
          </div>
          
          <div className="py-10">
            <ServicesSubSection data={visualDesign}/>
            <ServicesSubSection data={development}/>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ServicesSection;
