import { createSlice } from "@reduxjs/toolkit";

export const clientSlice=createSlice({
    name: "client",
    initialState:[],
    reducers:{
        getClients: (state,action)=>{
            return action.payload
        }
    }
})

export const {getClients}=clientSlice.actions
export default clientSlice.reducer