import React, { useEffect, useState } from 'react'
import PortfolioSubSection from './PortfolioSubSection'
import { collection, getDocs } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { getDesignPortfolio, getPortfolio } from '../../appStore/profileSlice';
import { db } from '../../firebase';



const PortfolioSection = () => {

  const [togglePortfolio,setTogglePortfolio]=useState(false)
  const [portfolioJson,setPortfolioJson]=useState(null);
  const [designPortfolioJson,setDesignPortfolioJson]=useState(null)

  const dispatch = useDispatch()

   

    useEffect(()=>{
      

      const getPortfolioData = async () => {
        const portfolio = await getDocs(collection(db, "portfolio"))
        const designPortfolio = await getDocs(collection(db,"design_portfolio"))
        
        const gettingDesignPortfolioJson = designPortfolio.docs.map((doc)=>doc.data())
    
        const gettingPortfolioJson = portfolio.docs.map((doc) => doc.data())
        setPortfolioJson(gettingPortfolioJson)
        setDesignPortfolioJson(gettingDesignPortfolioJson)

       
        
        
      };

      getPortfolioData()



    },[])
    dispatch(getDesignPortfolio(designPortfolioJson))
    dispatch(getPortfolio(portfolioJson))
    

    
    // getPortfolioData()

  
 

    // useEffect(() => {
    //     getPortfolioData();
    //   }, [getPortfolioData()]);
    


      const portfolioData = useSelector((store) => store.profile.portfolio);
      const designPortfolioData = useSelector((store)=>store.profile.designPortfolio)
      // console.log(designPortfolioData)

     const PortfolioDisplay=()=>{

      if(!togglePortfolio) {
        return(
           portfolioData && portfolioData.map((data)=>(
                <div key={data?.title}><PortfolioSubSection data={data}/></div>
            ))
        )
      }else{
        return(
          designPortfolioData && designPortfolioData.map((data)=>(
               <div key={data?.title}><PortfolioSubSection data={data}/></div>
           ))
       )

      }
      
      
     }
    



  return (
    <div>
        <div>
        <div className="sm:py-10 sm:px-24 px-6 text-center ">
        <div className="space-y-2">
          {" "}
          <div className="text-xl text-center text-green-400">Portfolio</div>
          <div className=" sm:text-6xl text-4xl sm:font-normal font-semibold  text-center  ">
          Take a closer look at what I've built.
          </div>
          <div className='flex justify-center gap-4 text-xl py-2'>
            <div className='border-[0.01rem] border-white px-8 py-2 cursor-pointer'  onClick={()=>{
              if(togglePortfolio){
                setTogglePortfolio(false)
              }
            }}>Development</div>
            <div className='border-[0.01rem] border-white px-8 py-2 cursor-pointer' onClick={()=>{
              if(!togglePortfolio){
                setTogglePortfolio(true)
              }
            }}>Design</div>
          </div>
          
          <div className="py-10 flex flex-wrap gap-x-10 gap-y-10 items-center justify-center">
            {/* {portfolioData && portfolioData.map((data)=>(
                <div key={data?.title}><PortfolioSubSection data={data}/></div>
            ))} */}
            <PortfolioDisplay/>
            
            
            
          </div>
        </div>
        <div></div>
      </div>
            

        </div>
      
    </div>
  )
}

export default PortfolioSection
