import React from "react";

const PortfolioSubSection = ({data}) => {
  // console.log(data)
  const {title,thumb_link,description}=data
  return (
    <div className="">
      
      <div className="">
     
        <div className="overflow-hidden bg-white  w-full h-72 sm:h-96 rounded-2xl">
        <img className="object-cover w-full h-full" src={thumb_link} alt=""/>
        </div>
        {title && (
          <div className="text-white text-left py-2">
          <div className=" text-white text-3xl font-semibold px-8 ">
            {title}
          </div>
          <div className=" text-white text-lg font-extralight px-8  ">
            {description}
          </div>
        </div>

        )}
        
      </div>
    </div>
  );
};

export default PortfolioSubSection;
