import React from 'react'

const Header = () => {
  return (
    <div className='absolute w-full'>
    <div className='flex justify-between px-8 sm:px-24 py-10'>
        <div>Logo</div>
        <div className='sm:block hidden'>
            <ul className='flex gap-x-10 '>
                <li>Home</li>
                <li>Service</li>
                <li>Portfolio</li>
            </ul>
        </div>
        <div className='border-[.01rem] px-4 py-1 rounded-xl border-green-400 text-green-400'>Get In Touch</div>
      
    </div>
    </div>
  )
}

export default Header
