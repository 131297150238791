import React, { useEffect, useState } from "react";

import { collection, getDocs } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { getClients } from "../../appStore/clientSlice";
import { db } from "../../firebase";
import ClientsCarousel from "./ClientsCarousel";


const ClientsSection = () => {

  const [clientJson,setClientJson]=useState(null);
 


  const dispatch =useDispatch()

  useEffect(()=>{
    
    const getClient = async () => {
      const client = await getDocs(collection(db, "clients"))
  
      const gettingClientJson = client.docs.map((doc) => doc.data())
      setClientJson(gettingClientJson)
      
      
    };
  
  
    getClient()



  },[])

  dispatch(getClients(clientJson))


  // useEffect(() => {
  //   getClient();
  // }, [getClient]);




  // console.log(clientsData)



  return (
    <div className="py-10 text-center ">
      <div>
        {" "}
        <div className="text-2xl ">Clients</div>
        <div className="px-8 text-xs font-extralight">
          Proud to collaborate with leading YouTube creators, crafting designs
          that elevate their content.
        </div>
        <div className="py-10">
          <ClientsCarousel />

        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ClientsSection;
