import { configureStore } from "@reduxjs/toolkit";
import clientReducer from "../appStore/clientSlice"
import profileReducer from "../appStore/profileSlice"

export default configureStore({
    reducer:{
        client:clientReducer,
        profile:profileReducer,

    }
})